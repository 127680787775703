import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
            title: 'Dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            title: 'Login',
            requiresAuth: false
        }
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/legals/Impressum.vue'),
        meta: {
            title: 'Impressum',
            requiresAuth: false
        }
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: () => import(/* webpackChunkName: "datenschutz" */ '../views/legals/Datenschutz.vue'),
        meta: {
            title: 'Datenschutzhinweis',
            requiresAuth: false
        }
    },
    {
        path: '/computers',
        name: 'ComputerList',
        component: () => import(/* webpackChunkName: "computerList" */ '../views/lists/ComputerList.vue'),
        meta: {
            title: 'Computerliste',
            requiresAuth: true
        }
    },
    {
        path: '/rzpcs',
        name: 'RzpcList',
        component: () => import(/* webpackChunkName: "rzpcList" */ '../views/lists/RzpcList.vue'),
        meta: {
            title: 'RZPC Liste',
            requiresAuth: true
        }
    },
    {
        path: '/groups',
        name: 'GroupList',
        component: () => import(/* webpackChunkName: "groupList" */ '../views/lists/GroupList.vue'),
        meta: {
            title: 'Gruppenweise',
            requiresAuth: true
        }
    },
    {
        path: '/software',
        name: 'SoftwareList',
        component: () => import(/* webpackChunkName: "softwareList" */ '../views/lists/SoftwareList.vue'),
        meta: {
            title: 'Softwareliste',
            requiresAuth: true
        }
    },
    {
        path: '/templates',
        name: 'TemplateList',
        component: () => import(/* webpackChunkName: "templateList" */ '../views/lists/TemplateList.vue'),
        meta: {
            title: 'Templateliste',
            requiresAuth: true
        }
    },
    {
        path: '/licenses',
        name: 'Licenses',
        component: () => import(/* webpackChunkName: "licensesList" */ '../views/lists/LicenseList.vue'),
        meta: {
            title: 'Lizenzen',
            requiresAuth: true,
            fg: false
        },
    },
    {
        path: '/checklist',
        name: 'Checklist',
        component: () => import(/* webpackChunkName: "checklist" */ '../views/lists/Checklist.vue'),
        meta: {
            title: 'Checkliste',
            requiresAuth: true,
            fg: false
        }
    },
    {
        path: '/processes',
        name: 'ProcessList',
        component: () => import(/* webpackChunkName: "processList" */ '../views/lists/ProcessList.vue'),
        meta: {
            title: 'Prozessfunde',
            requiresAuth: true
        }
    },
    {
        path: '/viruses',
        name: 'VirusList',
        component: () => import(/* webpackChunkName: "virusList" */ '../views/lists/VirusList.vue'),
        meta: {
            title: 'Virenfunde',
            requiresAuth: true
        }
    },
    {
        path: '/tasks',
        name: 'TaskList',
        component: () => import(/* webpackChunkName: "taskList" */ '../views/lists/TaskList.vue'),
        meta: {
            title: 'Aktionshistorie',
            requiresAuth: true
        }
    },
    {
        path: '/customers',
        name: 'CustomerList',
        component: () => import(/* webpackChunkName: "customerList" */ '../views/lists/CustomerList.vue'),
        meta: {
            title: 'Kundenliste',
            requiresAuth: true
        }
    },
    {
        path: '/exam-checklist',
        name: 'ExamChecklist',
        component: () => import(/* webpackChunkName: "examChecklist" */ '../views/fg-exam/ExamChecklist.vue'),
        meta: {
            title: 'Prüfungscheckliste',
            requiresAuth: true,
            fgExam: false
        }
    },
    {
        path: '/consulting-checklist',
        name: 'ConsultingChecklist',
        component: () => import(/* webpackChunkName: "consultingChecklist" */ '../views/consulting/ConsultingChecklist.vue'),
        meta: {
            title: 'Alternative Kostenträger',
            requiresAuth: true,
            consulting: false
        }
    },
    {
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
        meta: {
            title: 'News',
            requiresAuth: true
        }
    },
    {
        path: '/vpns',
        name: 'VPN',
        component: () => import(/* webpackChunkName: "vpns" */ '../views/lists/VPNList.vue'),
        meta: {
            title: 'VPNs',
            requiresAuth: true
        }
    },
    {
        path: '/shippingList',
        name: 'ShippingList',
        component: () => import(/* webpackChunkName: "shippingList" */ '../views/lists/ShippingList.vue'),
        meta: {
            title: 'Versandliste',
            requiresAuth: true
        }
    },
    {
        path: '/computer/:hostname',
        name: 'Computer',
        component: () => import(/* webpackChunkName: "computer" */ '../views/singleDisplay/Computer.vue'),
        meta: {
            title: 'Computer',
            requiresAuth: true,
            params: true
        }
    },
    {
        path: '/customer/:kdnr',
        name: 'Customer',
        component: () => import(/* webpackChunkName: "news" */ '../views/singleDisplay/Customer.vue'),
        meta: {
            title: 'Kunde',
            requiresAuth: true,
            params: true
        }
    },
    {
        path: '/profile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "news" */ '../views/userAccount/UserProfile.vue'),
        meta: {
            title: 'Benutzerprofil',
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'UserSettings',
        component: () => import(/* webpackChunkName: "news" */ '../views/userAccount/UserSettings.vue'),
        meta: {
            title: 'Benutzereinstellungen',
            requiresAuth: true
        }
    },
	{
		path: '/sites',
		name: 'SiteList',
		component: () => import(/* webpackChunkName: "SiteList" */ '../views/lists/SiteList.vue'),
		meta: {
			title: 'Standortliste',
			requiresAuth: true
		}
	},
    {
        path: '/services',
        name: 'ServiceList',
        component: () => import(/* webpackChunkName: "ServiceList" */ '../views/lists/ServiceList.vue'),
        meta: {
            title: 'Dienstübersicht',
            requiresAuth: true
        }
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue'),
        meta: {
            title: 'Feedback',
            requiresAuth: true,
            admin: false,
        }
    },
    {
        path: '/exam/exam-checklist',
        name: 'FGExamChecklist',
        component: () => import(/* webpackChunkName: "examChecklistFg" */ '../views/fg-exam/ExamChecklist.vue'),
        meta: {
            title: 'FG Prüfungscheckliste',
            requiresAuth: true,
            fgExam: true
        }
    },
    {
        path: '/consulting/consulting-checklist',
        name: 'FGConsultingChecklist',
        component: () => import(/* webpackChunkName: "checklistAdvice" */ '../views/consulting/ConsultingChecklist.vue'),
        meta: {
            title: 'Beratung Alternative Kostenträger',
            requiresAuth: true,
            consulting: true
        }
    },
    {
        path: '/fg-software/checklist',
        name: 'FGChecklist',
        component: () => import(/* webpackChunkName: "checklistFg" */ '../views/lists/Checklist.vue'),
        meta: {
            title: 'FG Checkliste',
            requiresAuth: true,
            fg: true
        }
    },
    {
        path: '/fg-software/licenses',
        name: 'FGLicenses',
        component: () => import(/* webpackChunkName: "licensesFg" */ '../views/lists/LicenseList.vue'),
        meta: {
            title: 'FG Lizenzen',
            requiresAuth: true,
            fg: true
        }
    },
    {
        path: '/fg-software/course-data',
        name: 'FGCourseData',
        component: () => import(/* webpackChunkName: "courseDataFg" */ '../views/fg-software/CourseDataManage.vue'),
        meta: {
            title: 'FG Kursdaten',
            requiresAuth: true
        }
    },
    {
        path: '/fg-software/software',
        name: 'FGSoftware',
        component: () => import(/* webpackChunkName: "softwareManage" */ '../views/fg-software/SoftwareManage.vue'),
        meta: {
            title: 'FG Software',
            requiresAuth: true
        }
    },
    {
        path: '/fg-software/templates',
        name: 'FGTemplates',
        component: () => import(/* webpackChunkName: "templateManage" */ '../views/fg-software/TemplateManage.vue'),
        meta: {
            title: 'FG Templates',
            requiresAuth: true
        }
    },
    {
        path: '/fg-software/webportals',
        name: 'FGWebPortals',
        component: () => import(/* webpackChunkName: "webportalManage" */ '../views/fg-software/WebPortalManage.vue'),
        meta: {
            title: 'FG Webportale',
            requiresAuth: true
        }
    },
    {
        path: '/fg-software/contracts',
        name: 'FGContracts',
        component: () => import(/* webpackChunkName: "contractsManage" */ '../views/fg-software/ContractsManage.vue'),
        meta: {
            title: 'FG Verträge',
            requiresAuth: true
        }
    },
    {
        path: '/admin/images',
        name: 'AdminImages',
        component: () => import(/* webpackChunkName: "imagesFG" */ '../views/admin/ImagesManage.vue'),
        meta: {
            title: 'Images',
            requiresAuth: true
        }
    },
    {
        path: '/admin/hardware',
        name: 'AdminHardware',
        component: () => import(/* webpackChunkName: "hardwareFG" */ '../views/admin/HardwareManage.vue'),
        meta: {
            title: 'Hardware',
            requiresAuth: true
        }
    },
    {
        path: '/admin/news',
        name: 'AdminNews',
        component: () => import(/* webpackChunkName: "newsFg" */ '../views/admin/NewsManage.vue'),
        meta: {
            title: 'News',
            requiresAuth: true
        }
    },
    {
        path: '/admin/feedback',
        name: 'AdminFeedback',
        component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue'),
        meta: {
            title: 'Admin Feedback',
            requiresAuth: true,
            admin: true,
        }
    },
    {
        path: '/admin/user-management',
        name: 'AdminUserManagement',
        component: () => import(/* webpackChunkName: "feedback" */ '../views/superadmin/UserManagement.vue'),
        meta: {
            title: 'User Management',
            requiresAuth: true,
            admin: true
        }
    },
    {
        path: '/session-expired',
        name: 'SessionExpired',
        component: () => import(/* webpackChunkName: "sessionExpired" */ '../views/SessionExpired'),
        meta: {
            title: 'Sitzung abgelaufen',
            requiresAuth: false,
        }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue'),
        meta: {
            title: '404',
            requiresAuth: true,
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

function handle_next(to, from, next, route) {
    if (route && route.name === 'Login') {
        route.query = {continue: window.location.origin + to.fullPath}
    }
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    let notificationCount = null
    if (from && from.meta && from.meta.notificationCount) {
        notificationCount = from.meta.notificationCount
    }
    if (nearestWithTitle) updateTitle(to, nearestWithTitle.meta.title, notificationCount)
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    if (!nearestWithMeta) return next(route);
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
    })
        .forEach(tag => document.head.appendChild(tag));
    next(route);
}

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && !JSON.parse(localStorage.getItem('vuex')) && to.meta.requiresAuth) handle_next(to, from, next, {name: 'Login'})
    else if (to.name === 'Login' && !JSON.parse(localStorage.getItem('vuex'))) handle_next(to, from, next)
    else if (to.name === 'Login' && JSON.parse(localStorage.getItem('vuex')).auth.authenticated) handle_next(to, from, next, {name: 'Home'})
    else if (to.meta.requiresAuth && !JSON.parse(localStorage.getItem('vuex')).auth.authenticated) handle_next(to, from, next, {name: 'Login'})
    else handle_next(to, from, next)
})

export function updateTitle(route, title, notificationCount=null) {
    let notificationCountText = ''
    if (notificationCount && notificationCount > 0) {
        route.meta.notificationCount = notificationCount
        notificationCountText = '(' + notificationCount + ') '
    }
    if(route.meta.title !== 'Kunde' && route.meta.title !== 'Computer') {
        document.title = notificationCountText + title + ' · alfaSMS'
    }
}

export default router
